import * as React from "react";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import { Resource } from "../classes/Resource";
import TechResContent from "../components/ui/TechnicalResources/Content/TechResContent";
// import get from "lodash/get";
import { colors } from "../vars/palette";
import styled from "styled-components";
import { BREAKPOINT_MEDIUM_MAX } from "../components/ui/Typography";
import FixedNav from "../components/ui/TechnicalResources/Nav/FIxedNav";
import TechButtons from "../components/ui/TechnicalResources/Buttons/TechButtons";
import CalcThrustRestraint from "../components/calculators/kind/Thrust";
import CalcSupports from "../components/calculators/kind/Supports";
import CalcHydraulic from "../components/calculators/kind/Hydraulic";
import CalcThicknessDesign from "../components/calculators/kind/Thickness";
import CalcLcca from "../components/calculators/kind/LCCA/LCCA";
import TechResNav from "../components/ui/TechnicalResources/Nav/TechResNav";
import DipraBreadcrumbs from "../components/Layout/Breadcrumbs";
import { WmkSeo } from "wmk-seo";
import { RolloverMenuFields } from "../fragments/NodeMenuFields";
import { MemberSectionPageQuery } from "../fragments/NodeMemberSectionPageFields";
import { MemberSectionPage } from "../classes/MemberSectionPage";
import MemberSectionButtons from "../components/ui/MemberSectionButtons";
import Locked from "../components/calculators/kind/LCCA/Locked";

const NullComponent = () => <></>;

const StyledContainer = styled(Container)`
  & > .row {
    min-height: 100vh;
  }
  .nav-container {
    @media print {
      display: none;
    }
    background-color: ${colors.techNavGreen.hex};
    padding: 14vh 3rem;
    a {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  @media only screen and (max-width: ${BREAKPOINT_MEDIUM_MAX}) {
    .nav-container {
      padding: 3vh 1.5rem;
      height: fit-content;
    }
  }
`;

const registeredCalculators = {
  "thrust-restraint-calculator": CalcThrustRestraint,
  "design-on-supports": CalcSupports,
  "hydraulic-analysis-and-greenhouse-gas-emissions": CalcHydraulic,
  "thickness-design": CalcThicknessDesign,
  "life-cycle-cost-analysis": CalcLcca
};

/**
 *
 * @param {Object} pageContext
 * @param {Object} data
 * @returns {JSX}
 */
const MemberSectionPageNode = ({
  pageContext,
  data
}: {
  pageContext: { parentPath: string; slug: string };
  data: MemberSectionPageNodeQuery;
}) => {
  const memberSectionPage = new MemberSectionPage(data.memberSectionPage);
  const techMenus = data.techMenus.edges.map((e) => e.node);
  let menuParentSlug;
  const thisSlug = "member-section";
  const menu = techMenus.reduce((match, m) => {
    const parentSlug = m.menuParent?.memberSectionPageSlug;
    const nearest = memberSectionPage.hierarchy.nearest().slug;

    if (parentSlug === thisSlug) {
      menuParentSlug = parentSlug;
      const childLinks = m.links.length ? m.links : [];
      return [...childLinks];
    } else {
      if (parentSlug === nearest) {
        menuParentSlug = parentSlug;
        const childLinks = m.links.length ? m.links : [];
        return [...childLinks];
      }
    }
    return match;
  }, []);
  const parent = memberSectionPage?.parent;
  const path = memberSectionPage?.parentPath;
  const slug = memberSectionPage?.slug;
  const isCalc = memberSectionPage?.title === `Life Cycle Cost Analysis`;
  const CalcJsx = isCalc ? CalcLcca : NullComponent;
  return (
    <>
      <WmkSeo.Meta
        title={
          memberSectionPage.serpTitle
            ? memberSectionPage.serpTitle
            : memberSectionPage.title
        }
        siteTitle={data.site.siteMetadata.title}
        baseUrl={data.site.siteMetadata.baseUrl}
        slug={`/${slug}`}
        path={memberSectionPage?.parentPath === "/member-section/" ? "" : memberSectionPage.parentPath}
        description={memberSectionPage.meta}
        ogImageUrl={memberSectionPage.ogImage ? memberSectionPage.ogImage : ""}
        twitterImageUrl={
          memberSectionPage.twitterImage ? memberSectionPage.twitterImage : ""
        }
        twitterHandle={data.site.siteMetadata.twitter}
      />
      <Locked>

      <StyledContainer fluid>
        <Row style={{}}>
          <Col className="nav-container" xs={12} sm={12} md={5} lg={4}>
            <TechResNav
              resource={memberSectionPage ? memberSectionPage : undefined}
              menu={menu}
              slug={pageContext.slug}
            />
          </Col>
          <Col xs={12} sm={12} md={7} lg={8}>
            {/* content-target div used to snap page down to the content when a resource nav item is clicked on mobile */}
            <div id="content-target" />
            <DipraBreadcrumbs route={`${path} `} />
            {isCalc ? (
              <CalcJsx />
            ) : (
              <TechResContent resource={memberSectionPage} />
            )}
          </Col>
        </Row>
      </StyledContainer>
      <FixedNav parentSlug={menuParentSlug ? menuParentSlug : thisSlug}>
        <MemberSectionButtons menu={menu} slug={pageContext.slug} />
      </FixedNav>
      </Locked>
    </>
  );
};

export default MemberSectionPageNode;

export interface MemberSectionPageNodeQuery {
  memberSectionPage: MemberSectionPageQuery;
  techMenus: {
    edges: {
      node: RolloverMenuFields;
    }[];
  };
  site: {
    siteMetadata: {
      description: string;
      baseUrl: string;
      title: string;
      twitter: string;
    };
  };
}

export const query = graphql`
  {
    memberSectionPage: contentfulMemberSectionPage(slug: {eq: "member-section"}) {
      ...NodeMemberSectionPageFields
      ...MemberSectionPageBlockIconCopy
      ...MemberSectionPageBlockImageCopy
      ...MemberSectionPageAsset
      ...MemberSectionPageBlockTestimonial
      ...MemberSectionPageBlockMediaAssetsGallery
    }
    techMenus: allContentfulMenu(
      filter: { title: { regex: "/member section/i" } }
    ) {
      edges {
        node {
          ...NodeMenuFields
        }
      }
    }
    site {
      siteMetadata {
        baseUrl
        title
        twitter
      }
    }
  }
`;
